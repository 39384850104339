export default {
  title: {

  },
  table: {
    pic: 'ユーザー名',
    explain: '変更内容',
    action: '操作種別',
    ip: 'IPアドレス',
    date_time: '日時',
  },
  action: {
    CREATE: '新規作成',
    DELETE: '削除',
    EDIT: '編集',
  },
  form: {

  },
  label: {
    product_info: '商品情報',
  },
};
