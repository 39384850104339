import Vue from 'vue';
import VueRouter from 'vue-router';
// import HomeView from '../views/HomeView.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "list-order" */ '../views/orders/ListOrder.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/orders',
    name: 'orders',
    component: () =>
      import(/* webpackChunkName: "list-order" */ '../views/orders/ListOrder.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/orders/:order_id',
    name: 'detail_order',
    component: () =>
      import(/* webpackChunkName: "detail-order" */ '../views/orders/InputOrder.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/orders/:order_id/history',
    name: 'history_order',
    component: () =>
      import(/* webpackChunkName: "detail-order-history" */ '../views/orders/HistoryOrder.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/products',
    name: 'products',
    component: () =>
      import(/* webpackChunkName: "list-product" */ '../views/products/ListProduct.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/products/create',
    name: 'create_product',
    component: () =>
      import(/* webpackChunkName: "create-product" */ '../views/products/InputProduct.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/products/:product_id',
    name: 'detail_product',
    component: () =>
      import(/* webpackChunkName: "detail-product" */ '../views/products/InputProduct.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/schools',
    name: 'schools',
    component: () =>
      import(/* webpackChunkName: "list-school" */ '../views/schools/ListSchool.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/schools/create',
    name: 'create_school',
    component: () =>
      import(/* webpackChunkName: "create-school" */ '../views/schools/InputSchool.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/schools/:school_id',
    name: 'detail_school',
    component: () =>
      import(/* webpackChunkName: "detail-school" */ '../views/schools/InputSchool.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/collections',
    name: 'collections',
    component: () =>
      import(/* webpackChunkName: "list-collection" */ '../views/collections/ListCollection.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/collections/create',
    name: 'create_collection',
    component: () =>
      import(/* webpackChunkName: "create-collection" */ '../views/collections/InputCollection.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/collections/:collection_id',
    name: 'detail_collection',
    component: () =>
      import(/* webpackChunkName: "detail-collection" */ '../views/collections/InputCollection.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () =>
      import(/* webpackChunkName: "list-customer" */ '../views/customers/ListCustomer.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/customers/:customer_id',
    name: 'detail_customer',
    component: () =>
      import(/* webpackChunkName: "detail-customer" */ '../views/customers/InputCustomer.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/suppliers',
    name: 'suppliers',
    component: () =>
      import(/* webpackChunkName: "list-supplier" */ '../views/suppliers/ListSupplier.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/suppliers/create',
    name: 'create_supplier',
    component: () =>
      import(/* webpackChunkName: "create-supplier" */ '../views/suppliers/InputSupplier.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/suppliers/:supplier_id',
    name: 'detail_supplier',
    component: () =>
      import(/* webpackChunkName: "detail-supplier" */ '../views/suppliers/InputSupplier.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/admin-setting',
    name: 'admin_setting',
    component: () =>
      import(/* webpackChunkName: "admin-setting" */ '../views/AdminSetting.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () =>
      import(/* webpackChunkName: "list-faq" */ '../views/faqs/ListFaq.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/faqs/create',
    name: 'create_faq',
    component: () =>
      import(/* webpackChunkName: "create-faq" */ '../views/faqs/InputFaq.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/faqs/:faq_id',
    name: 'detail_faq',
    component: () =>
      import(/* webpackChunkName: "detail-faq" */ '../views/faqs/InputFaq.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/specs',
    name: 'specs',
    component: () =>
      import(/* webpackChunkName: "list-spec" */ '../views/specs/ListSpec.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/specs/create',
    name: 'create_spec',
    component: () =>
      import(/* webpackChunkName: "create-spec" */ '../views/specs/InputSpec.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/specs/:spec_id',
    name: 'detail_spec',
    component: () =>
      import(/* webpackChunkName: "detail-spec" */ '../views/specs/InputSpec.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/mysize-setting',
    name: 'mysize_setting',
    component: () =>
      import(/* webpackChunkName: "mysize-setting" */ '../views/sizes/MysizeSetting.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/transports',
    name: 'transports',
    component: () =>
      import(/* webpackChunkName: "list-transport" */ '../views/transports/ListTransport.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/transports/create',
    name: 'create_transport',
    component: () =>
      import(/* webpackChunkName: "create-transport" */ '../views/transports/InputTransport.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/transports/:transport_id',
    name: 'detail_transport',
    component: () =>
      import(/* webpackChunkName: "detail-transport" */ '../views/transports/InputTransport.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/transports/fees/edit',
    name: 'create_transport_fee',
    component: () =>
      import(/* webpackChunkName: "input-transport-fee" */ '../views/transports/InputTransportFee.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/transports/:transport_id/fees',
    name: 'update_transport_fee',
    component: () =>
      import(/* webpackChunkName: "input-transport-fee" */ '../views/transports/InputTransportFee.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/pages/return-policy',
    name: 'return-policy',
    component: () =>
      import(/* webpackChunkName: "input-page" */ '../views/pages/InputPage.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/pages/privacy-policy',
    name: 'privacy-policy',
    component: () =>
      import(/* webpackChunkName: "input-page" */ '../views/pages/InputPage.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/pages/terms-of-use',
    name: 'terms-of-use',
    component: () =>
      import(/* webpackChunkName: "input-page" */ '../views/pages/InputPage.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/pages/commercial-transaction-law',
    name: 'commercial-transaction-law',
    component: () =>
      import(/* webpackChunkName: "input-page" */ '../views/pages/InputPage.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/pages/how-to-buy',
    name: 'how-to-buy',
    component: () =>
      import(/* webpackChunkName: "input-page" */ '../views/pages/InputPage.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/banks',
    name: 'banks',
    component: () =>
      import(/* webpackChunkName: "input-bank" */ '../views/banks/InputBank.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/admin/edit-information',
    name: 'edit_admin',
    component: () =>
      import(/* webpackChunkName: "edit-admin" */ '../views/admins/EditAdmin.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/page/how-to-cancel-spam',
    name: 'how-to-cancel-spam',
    component: () =>
      import(/* webpackChunkName: "input-page" */ '../views/pages/InputPage.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/page/external-characters',
    name: 'external-characters',
    component: () =>
      import(/* webpackChunkName: "input-page" */ '../views/pages/InputPage.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/email-logs',
    name: 'email-logs',
    component: () =>
      import(/* webpackChunkName: "email-logs" */ '../views/email-logs/ListEmailLog.vue'),
    meta: { layout: 'admin' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = store.getters['userStore/token'];
  if (to.name !== 'login' && token === '') {
    return next({ name: 'login' });
  }
  next();
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  }
});

export default router;
